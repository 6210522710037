<template>
<div>
  <NavBar name="数据总览"></NavBar>
  <div class="datapreview">
    <el-form :inline="true" class="content-main">
      <el-form-item>
        <el-input placeholder="请输入小区名称" clearable v-model="params.market_name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="params.market_contacts" clearable placeholder="请输入管理员姓名"></el-input>
      </el-form-item>
      <el-form-item label="">
        <myButton title="搜索" @click="getList">
          <template #preImage>
            <img src="@/unit/img/search.png" alt="" />
          </template>
        </myButton>
      </el-form-item>
      <el-form-item>
        <myButton title="导出" @click="exportDataEvent">
          <template #preImage>
            <img src="@/unit/img/down.png" alt="" />
          </template>
        </myButton>
      </el-form-item>
      <el-form-item>
        <div class="ysall">
          <span>当月应收合计:</span>
          <span>{{dataDetail && dataDetail.total_price || 0}}</span>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="ysall">
          <span>当月已收合计:</span>
          <span>{{dataDetail && dataDetail.pay_price || 0}}</span>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="ysall">
          <span>当月未收合计:</span>
          <span>{{dataDetail && dataDetail.not_pay_price || 0}}</span>
        </div>
      </el-form-item>
    </el-form>
    <vxe-table ref="xTable1" :row-config="{ isHover: true }" :show-overflow="showOverflow1" :data="tableData" header-cell-class-name="table-header" cell-class-name="table-cell">
      <vxe-column field="market_name" title="小区名称"></vxe-column>
      <vxe-column field="market_address" title="地址信息"></vxe-column>
      <vxe-column field="market_statistic.total_price" title="当月应收合计（元）"></vxe-column>
      <vxe-column field="market_statistic.pay_price" title="当月已收合计（元）"></vxe-column>
      <vxe-column field="market_statistic.not_pay_price" title="当月未收合计（元）"></vxe-column>
      <vxe-column field="market_statistic.pay_rate" title="收费率">
        <template #default="{ row }">
          <span>{{parseFloat(row.market_statistic.pay_rate).toFixed(2)}}%</span>
        </template>
      </vxe-column>
      <vxe-column field="editTime" title="操作">
        <template #default="{ row }">
          <pop style="margin: 0 16px" tips="详情" @myclick="goUrl(row)">
            <img class="icon" src="@/assets/img/icon/xiangqing.png" alt />
          </pop>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-pager :current-page="params.pageNum" :page-size="params.pageSize" :total="total" :layouts="['PrevPage', 'JumpNumber', 'NextPage', , 'Total']" @page-change="getList">
    </vxe-pager>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      showOverflow1: false,
      params: {
        market_name: '',
        market_contacts: '',
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      tableData: [],
      dataDetail: null
    };
  },
  created() {
    this.getList();
    this.getTotal();
  },
  methods: {
    getTotal() {
      this.$request.HttpPost('/community/property/index-total').then(res => {
        this.dataDetail = res.data;
      });
    },
    exportDataEvent() {
      this.$refs.xTable1.exportData({
        type: "csv",
        filename: `数据总览${new Date().toLocaleString()}`,
        columnFilterMethod ({ column }) {
          return ['market_name', 'market_address', 'market_statistic.total_price', 'market_statistic.pay_price', 'market_statistic.not_pay_price', 'market_statistic.pay_rate'].includes(column.property)
        }
      });
    },
    getList(e = { currentPage: 1 }) {
      this.params.pageNum = e.currentPage;
      this.$request.HttpPost('/community/property/index-list', this.params).then(res => {
        this.tableData = res.data.list || [];
        this.total = res.data.total;
      });
    },
    goUrl(item) {
      this.$router.push({
        path: '/index/community/dataView/dataDetail',
        query: {
          id: item.market_id
        }
      });
    },
  },
};
</script>
<style lang="scss">
.ysall {
    display: inline-block;
    width: 160px;
    height: 32px;
    background: #02ce80;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    padding: 0 10px;
    box-sizing: border-box;
    line-height: 32px;
    span {
        &:nth-child(2) {
            font-weight: bold;
            margin-left: 12px;
        }
    }
}
</style>
